<template>
  <div style="padding: 0 10px;">
    <div class="Level_box0">
      <div class="Level_title">MTDAO ID VIP</div>
      <div class="Level_box1 flex_lr hand" v-for="(item,index) in $t('idLevel.arr')" @click="toUrl(item.url)">
        <div class="Level_text">{{item.title}}</div>
        <i class="ifont icone-qianjin" style="color:#ffffff" />
      </div>
      <div style="height:50px;"></div>
    </div>
  </div>
</template>
 
<script>
export default {
  data () {
    return {
      
    }
  },
  mounted () {
    // this.init()
  },
  methods: {
    init() {
      this.get('', {}, 'POST').then(res => {
        
      })
    },
    toUrl(url){
      if(url==''){
        this.$toast(this.$t('tips.title'))
        return
      }
      this.$router.push(url)
    }
  }
}
</script>
 
<style lang="scss" scoped>
  .Level_title{
    color: #ffffff;
    font-size: 1.5rem;
    padding: 20px 0;
    text-align: center;
  }
  .Level_text{
    color: #ffffff;
    font-size: 0.8rem;
    padding: 3px 0;
  }
  .Level_box0{
    width: 100%;
    background: #010C1B;
    border-radius:5px;
    padding: 0 10px;
    margin: 10px 0;
  }
  .Level_box1{
    width: 100%;
    background: #0C2379;
    border-radius: 8px;
    padding: 10px 15px;
    margin-bottom: 10px;
  }
</style>