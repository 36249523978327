import { render, staticRenderFns } from "./idLevel.vue?vue&type=template&id=5709febb&scoped=true&"
import script from "./idLevel.vue?vue&type=script&lang=js&"
export * from "./idLevel.vue?vue&type=script&lang=js&"
import style0 from "./idLevel.vue?vue&type=style&index=0&id=5709febb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5709febb",
  null
  
)

export default component.exports